<template>
  <div>
    <!-- fixed top header -->
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button type="success" @click="saveTransfer('transferForm')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header title="Буцаалт үүсгэх"/>
        <!-- form error template -->
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <!-- Transfer form -->
        <el-form label-position="top" :model="transferForm" ref="transferForm" v-loading="loading">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <ul class="transfer-product-list" v-if="transferForm.products.length > 0">
                    <div v-for="(product) in transferForm.products" :key="product.productId">
                      <li v-if="product.variants.length > 0">
                        <el-row :gutter="10" type="flex" align="middle">
                          <el-col :span="2">
                            <div class="image-holder">
                              <img :src="product.image" alt="">
                            </div>
                          </el-col>
                          <el-col :span="20">
                            {{product.name_mon}}
                          </el-col>
                          <el-col :span="2" class="text-right">
                            <i @click="deleteProductFromTransfer(product)" class="el-icon-close cursor-pointer"></i>
                          </el-col>
                        </el-row>
                        <ul>
                          <li v-for="(variant) in product.variants" :key="variant.variant_id">
                            <el-row :gutter="20" type="flex" align="middle">
                              <el-col :span="16" :offset="2">
                                <div>
                                  {{variant.variant_name}}
                                </div>
                                <div class="text-color-secondary">
                                  {{variant.variant_sku}}
                                </div>
                              </el-col>
                              <el-col :span="6">
                                <div class="text-center">
                                  <el-form-item :class="errorArray.filter(item => item.key === variant.variant_sku).length > 0 ? 'is-error': ''">
                                    <el-input
                                      type="number"
                                      min="0"
                                      v-model="variant.number_tobe_transferred"
                                    />
                                  </el-form-item>
                                </div>
                              </el-col>
                              <el-col :span="2" class="text-right">
                                <i @click="deleteVariantFromTransfer(product, variant)" class="el-icon-close cursor-pointer"></i>
                              </el-col>
                            </el-row>
                          </li>
                        </ul>
                      </li>
                    </div>
                  </ul>
                  <div v-else class="p20 text-color-secondary text-center">Бараа сонгогдоогүй байна</div>
                </div>
              </div>
            </el-col>
            <!-- right sidebar -->
            <el-col :span="8">
              <div class="panel">
                <div class="panel-item">
                  <div v-if="warehouses">
                    <el-form-item
                    label="Агуулах"
                    prop="warehouse_id"
                    :rules="[{ required: true, message: 'Агуулах сонгоно уу', trigger: 'blur' }]">
                      <el-select v-model="transferForm.warehouse_id" style="width: 100%" placeholder="Сонгох" @change="getSuppliers">
                        <el-option
                            v-for="(item, index) in warehouses"
                            :key="index"
                            :label="item.name_mon"
                            :value="item.warehouse_id"
                          >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="panel" v-loading="supplierLoading">
                <div class="panel-item">
                  <el-form-item
                    label="Нийлүүлэгч байгууллага"
                    prop="supplier_id"
                    :rules="[{ required: true, message: 'Нийлүүлэгч байгууллага сонгоно уу', trigger: 'blur' }]">
                    <el-select
                      style="width: 100%"
                      v-model="transferForm.supplier_id"
                      @change="setCurrentSupplier"
                      filterable
                      placeholder="Нийлүүлэгч"
                    >
                      <el-option
                        v-for="(item, index) in suppliers"
                        :key="index"
                        :label="item.supplier_monName"
                        :value="item.supplier_id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <header>Хүлээн авах огноо</header>
                  <el-form-item
                  label="Огноо">
                    <el-date-picker
                      value-format="yyyy-MM-dd HH:mm:ss"
                      v-model="transferForm.expected_arrival_at"
                      type="datetime"
                      placeholder="YYYY-MM-DD"
                      format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="text-right border-top pt20">
            <el-button type="default" @click="confirmLeaveForm = true"
              >Болих</el-button
            >
            <el-button type="success" @click="saveTransfer('transferForm')">Хадгалах</el-button>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- Product search -->
    <el-dialog
      title="Бүтээгдэхүүн сонгох"
      :visible.sync="visibleProductDialog"
      width="500"
    >
      <div>
        <el-input
          placeholder="Барааны нэр, SKU ..."
          v-model="searchText"
        >
          <template slot="prepend"><i class="el-icon-search"></i></template>
        </el-input>
      </div>
      <div class="mt20" v-loading="productLoading">
        <ul class="transfer-product-list" v-if="products.length > 0">
          <li v-for="product in products.filter(data => !searchText || data.productId.toString().toLowerCase().includes(searchText.toString().toLowerCase()) || data.name.toString().toLowerCase().includes(searchText.toString().toLowerCase()))" :key="product.productId">
            <el-row :gutter="10" type="flex" align="middle">
              <el-col :span="2">
                <div class="image-holder">
                  <img :src="product.image" alt="">
                </div>
              </el-col>
              <el-col :span="20">
                {{product.name_mon}}
              </el-col>
              <el-col :span="2" class="text-right">
                <el-checkbox v-model="product.checked" :indeterminate="product.isIndeterminate" @change="checkProduct(product)"></el-checkbox>
              </el-col>
            </el-row>
            <ul>
              <li v-for="variant in product.variants" :key="variant.variant_id">
                <el-row :gutter="20" type="flex" align="middle">
                  <el-col :span="18" :offset="2">
                    <div>
                      {{variant.variant_name}}
                    </div>
                    <div class="text-color-secondary">
                      {{variant.variant_sku}}
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <div class="text-center">
                      {{variant.availableCount}}
                    </div>
                  </el-col>
                  <el-col :span="2" class="text-right">
                    <el-checkbox v-model="variant.checked" @change="checkVariant(product, variant)"></el-checkbox>
                  </el-col>
                </el-row>
              </li>
            </ul>
          </li>
        </ul>
        <div v-else class="text-center mtb30">
          <div class="text-color-placeholder">
            <p>
              Бараа байхгүй байна
              <router-link to="/add-product">
                бүтээгдэхүүн нэмэх
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleProductDialog = false">Cancel</el-button>
        <el-button type="success" @click="setTransferProducts">
          Add to return
        </el-button>
      </span>
    </el-dialog>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/return">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import services from '../../../helpers/services'
import CustomHeader from '../../../components/common/customHeader'
export default {
  name: 'addTransfer',
  components: {
    CustomHeader
  },
  data () {
    return {
      errorArray: [],
      supplierForm: {
        name: ''
      },
      expectedDate: null,
      searchText: '',
      currentSupplier: null,
      confirmLeaveForm: false,
      visibleSupplierDialog: false,
      visibleProductDialog: false,
      suppliers: [],
      products: [],
      loading: false,
      productLoading: false,
      supplierLoading: false,
      transferForm: {
        supplier_id: '',
        products: [],
        warehouse_name: 'default',
        warehouse_id: null,
        expected_arrival_at: null
      },
      warehouses: []
    }
  },
  created () {
    this.getWarehouses()
  },
  methods: {
    closeModal () {
      this.visibleSupplierDialog = false
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    },
    getWarehouses () {
      services.getWarehouses('').then(response => {
        this.warehouses = response.datas
      })
    },
    setCurrentSupplier (val) {
      this.suppliers.forEach((element) => {
        if (element.supplier_id === val) {
          this.currentSupplier = element
        }
      })
      this.transferForm.supplier_id = this.currentSupplier.supplier_id
      this.getProductsWithVariant()
      this.visibleProductDialog = true
    },
    getProductsWithVariant () {
      this.productLoading = true
      services.getProductsWithVariantBySupplierId(this.currentSupplier.supplier_id).then(response => {
      // product check, variants check property add
        response.forEach(product => {
          const property = {
            checked: false,
            isIndeterminate: false
          }
          Object.assign(product, property, product)
          product.variants.forEach(variant => {
            const property = {
              checked: false,
              number_tobe_transferred: 0
            }
            Object.assign(variant, property, variant)
          })
        })
        this.products = response
        this.productLoading = false
      })
    },
    checkProduct (product) {
      product.variants.forEach(variant => {
        variant.checked = product.checked
      })
    },
    checkVariant (product, variant) {
      let checkedCount = 0
      product.variants.forEach(variant => {
        if (variant.checked) {
          checkedCount = checkedCount + 1
        }
      })
      product.checked = checkedCount === product.variants.length
      product.isIndeterminate = checkedCount > 0 && checkedCount < product.variants.length
    },
    setTransferProducts () {
      const tempProducts = []
      this.products.forEach(product => {
        const variants = [...product.variants.filter(variant => variant.checked)]
        if (variants.length > 0) {
          product.variants = variants
          tempProducts.push(product)
        }
      })
      if (tempProducts.length > 0) {
        this.transferForm.products = tempProducts
        this.visibleProductDialog = false
      } else {
        this.$notify.error({
          title: 'Oops',
          position: 'top-right',
          message: 'Бүтээгдэхүүн сонгоно уу',
          duration: 2000
        })
      }
    },
    deleteVariantFromTransfer (product, delVariant) {
      product.variants = product.variants.filter(variant => variant !== delVariant)
    },
    deleteProductFromTransfer (delProduct) {
      this.transferForm.products = this.transferForm.products.filter(product => product !== delProduct)
    },
    saveTransfer (formName) {
      this.errorArray = []
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const sendBody = {
            products: [],
            warehouse_name: this.transferForm.warehouse_name,
            expected_arrival_at: this.transferForm.expected_arrival_at,
            supplier_id: this.transferForm.supplier_id,
            warehouse_id: this.transferForm.warehouse_id
          }
          this.transferForm.products.forEach(product => {
            product.variants.forEach(variant => {
              if (variant.number_tobe_transferred < 1) {
                this.errorArray.push(
                  {
                    key: variant.variant_sku,
                    value: `${variant.variant_sku} дугаартай барааны татан авах ширхэг 1 ээс их байх ёстой`
                  }
                )
              }
              const productBody = {
                product_id: variant.product_id,
                sku: variant.variant_sku,
                number_tobe_transferred: variant.number_tobe_transferred,
                variant_id: variant.variant_id
              }
              sendBody.products.push(productBody)
            })
          })
          if (this.transferForm.products.length === 0) {
            this.errorArray.push({
              key: '',
              value: 'Бүтээгдэхүүн сонгогдоогүй байна'
            })
          }
          if (this.errorArray.length === 0) {
            this.loading = true
            services.addReturn(sendBody).then(data => {
              if (data.status === 'success') {
                this.alertReporter('Амжилттай', data.message, 'success')
                this.loading = false
                this.$router.push({ name: 'return' })
              } else if (data.status === 'error') {
                this.alertReporter('Алдаа', data.message, 'error')
                this.loading = false
              }
            })
          }
        }
      })
    }
  }
}
</script>
<style>
.el-input-group__prepend {
  background-color: #ffffff;
}
.el-table::before {
  content: unset;
}
.el-table tr:last-child td {
  border-bottom: none;
}
</style>
